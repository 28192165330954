<template>
	<div>
		<RouterView />
	</div>
</template>

<script setup>

import {
	UPDATE_SITE_DATA,
	ADD_DOCUMENT_ELEMENTS,
	NAVIGATE_TO_PATH,
	PREVIEW_FRAME_MOUNTED,
} from '@zyro-inc/site-modules/constants/messageEvents';

import { useEcommerceGlobal } from '@/use/useEcommerceGlobal';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { addDocumentElementsByRoutePath } from '@/utils/addDocumentElements';

const store = useStore();
const router = useRouter();
const { setShoppingCartItems } = useEcommerceGlobal();

/**
 * Listen for message events to allow setting data externally
 * Used for previews
 */
const handleWindowMessage = (data) => {
	if (typeof data !== 'object') {
		return;
	}

	if (data.type === UPDATE_SITE_DATA) {
		store.commit('setWebsite', {
			website: data.payload.siteData,
		});

		if (data.payload.path && data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}

		setShoppingCartItems([]);
	}

	// NAVIGATE_TO_PATH event is used by other internal services (site-position-service, screenshot-service)
	if (data.type === NAVIGATE_TO_PATH) {
		if (data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}
	}

	if (data.type === ADD_DOCUMENT_ELEMENTS) {
		addDocumentElementsByRoutePath({
			path: router.currentRoute.value.path,
			siteData: data.payload.siteData,
		});
	}
};

window.addEventListener('message', ({ data }) => handleWindowMessage(data));

window.parent.postMessage({
	type: PREVIEW_FRAME_MOUNTED,
}, '*');

</script>
