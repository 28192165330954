import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import * as Vue from 'vue';

import { addDocumentElementsByRoutePath } from '@/utils/addDocumentElements';

import {
	dataQADirective,
	QA_DIRECTIVE_NAME,
} from '@zyro-inc/site-modules/directives/dataQaDirective';

import '@/assets/scss/global.scss';
import App from '@/App.vue';
import { fetchSiteData } from '@/utils/fetchSiteData';
import router from '@/router';
import store from '@/store';

if ('ResizeObserver' in window === false) {
	window.ResizeObserver = ResizeObserverPolyfill;
}

const app = Vue.createApp({
	mounted: () => {
		// 'site-engine-mount' custom event is used in prerender service
		// it notifies lambda that app is mounted and it could save the HTML output
		document.dispatchEvent(new Event('site-engine-mount'));
		// when all external dependencies are loaded, fire 'DOMContentLoaded', because some external scripts depend on it
		window.addEventListener('load', () => document.dispatchEvent(new Event('DOMContentLoaded')));
	},
	watch: {
		$route: {
			handler(route) {
				if (route && this.$store.state.website) {
					addDocumentElementsByRoutePath({
						path: route.path,
						siteData: this.$store.state.website,
					});
				}
			},
			deep: true,
		},
	},
	render: () => Vue.h(App),
})
	.use(router)
	.use(store);

fetchSiteData().then((siteData) => {
	// Don't overwrite website if it's already set
	// Currently we have only 1 such condition - postMessage in builder preview sets website before this fetch
	if (siteData && !store.state.website) {
		addDocumentElementsByRoutePath({
			path: router.currentRoute.value.path,
			siteData,
		});
		store.commit('setWebsite', {
			website: siteData,
		});
	}
}).finally(() => {
	app.directive(QA_DIRECTIVE_NAME, dataQADirective);
	app.mount('#app');
});
