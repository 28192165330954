import {
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { getCartData } from '@zyro-inc/site-modules/utils/ecommerce/cartData';
import {
	getStoreProducts,
	getVariantsQuantity,
} from '@/api/StoreApi';
import {
	MAX_PRODUCTS_IN_CART,
	PRODUCT_TYPE_BOOKING,
	SHOPPING_CART_STORAGE_KEY,
	SHOPPING_CART_TTL,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import {
	EcommerceProduct,
	EcommerceProductVariantQuantity,
} from '@zyro-inc/site-modules/types';

const products = ref<EcommerceProduct[]>([]);
const shoppingCartItems = ref<any[]>([]);
const variantsQuantity = ref<EcommerceProductVariantQuantity[]>([]);
const selectedBookingProductId = ref(null);

const isShoppingCartOpen = ref(false);
const isCheckoutLoading = ref(false);
const isLoading = ref(false);
const isLoaded = ref(false);
const isProductPageLoaded = ref(false);

export const useEcommerceGlobal = () => {
	// TO-DO: remove this after index store is refactored
	const { getters } = useStore();

	const isStoreTypeZyro = computed(() => getters.meta[META_ECOMMERCE_TYPE] === ECOMMERCE_TYPE_ZYRO);
	const isEcommerceStoreCreated = computed(() => !!getters.meta?.ecommerceStoreId);

	const quantifiedCartItemsList = computed(() => shoppingCartItems.value.reduce((quantifiedProducts, product) => {
		const existingProduct = quantifiedProducts.find(
			(searchedProduct: any) => searchedProduct.product.variants[0].id === product.variants[0].id,
		);

		if (existingProduct) {
			return quantifiedProducts.map((productToUpdate: any) => {
				if (productToUpdate.product.variants[0].id === product.variants[0].id) {
					return {
						...productToUpdate,
						quantity: productToUpdate.quantity + 1,
					};
				}

				return productToUpdate;
			});
		}

		return [
			...quantifiedProducts,
			{
				product,
				quantity: 1,
			},
		];
	}, []));

	const canAddToCart = (productId: string, productVariantId: string) => {
		if (shoppingCartItems.value.length >= MAX_PRODUCTS_IN_CART) {
			return false;
		}

		const product = products.value.find((item) => item.id === productId);
		const variant = product?.variants.find((item) => item.id === productVariantId);

		if (!product || !variant) {
			return false;
		}

		if (variant.manage_inventory) {
			const cartProduct = quantifiedCartItemsList.value
				.find((productItem: any) => productItem.product.id === productId
          && productItem.product.variants.some((variantItem: any) => variantItem.id === variant.id));
			const quantity = cartProduct?.quantity || 0;
			const availableQuantity = variantsQuantity.value.find((item) => item.id === productVariantId)?.inventory_quantity as number;

			return quantity < availableQuantity;
		}

		return true;
	};

	const productPages = computed(() => Object.values(getters.pages).filter((page: any) => page.type === PAGE_TYPE_ECOMMERCE_PRODUCT));

	const setIsLoading = (payload: boolean) => {
		isLoading.value = payload;
	};

	const setIsLoaded = (payload: boolean) => {
		isLoaded.value = payload;
	};

	const setIsCheckoutLoading = (payload: boolean) => {
		isCheckoutLoading.value = payload;
	};

	const setStoreProducts = (payload: any) => {
		products.value = payload;
	};

	const setShoppingCartOpen = (payload: boolean) => {
		isShoppingCartOpen.value = payload;
	};

	const setShoppingCartItems = (payload: any) => {
		shoppingCartItems.value = payload || [];
	};

	const setSelectedBookingId = (payload: any) => {
		selectedBookingProductId.value = payload;
	};

	const setVariantsQuantity = (payload: any) => {
		variantsQuantity.value = payload;
	};

	const refreshCartItems = ({ cartItems }: { cartItems: any }) => (
		(cartItems || shoppingCartItems.value).reduce((accumulator: any, cartItem: any) => {
			const productMatch = products.value.find((productItem) => productItem.id === cartItem.id);

			const variantMatch = productMatch?.variants
				.find((variantItem) => cartItem.variants
					.some((cartVariantItem: any) => variantItem.id === cartVariantItem.id));

			const quantity = accumulator.reduce((counter: any, item: any) => {
				if (item.variants.some((variant: any) => variant.id === variantMatch?.id)) {
					return counter + 1;
				}

				return counter;
			}, 0);

			const availableQuantity = variantsQuantity.value.find(
				((variant) => variant.id === variantMatch?.id),
			)?.inventory_quantity as number;

			const isQuantityValid = !variantMatch?.manage_inventory || quantity < availableQuantity;

			const bookingEvent = productMatch?.type.value === PRODUCT_TYPE_BOOKING ? {
				...cartItem.variants[0].booking_event,
				time_slot: cartItem.variants[0].booking_event.time_slot,
				date: cartItem.variants[0].booking_event.date,
			} : null;

			if (productMatch && variantMatch && isQuantityValid) {
				return [
					...accumulator,
					{
						...productMatch,
						variants: [
							{
								...variantMatch,
								booking_event: bookingEvent,
							},
						],
					},
				];
			}

			return accumulator;
		}, []));

	const updateVariantsQuantity = async (idsToFetch: string[]) => {
		const storeId = getStoreId(getters.meta);

		if (!storeId) {
			return;
		}

		try {
			const quantity = await getVariantsQuantity(storeId, idsToFetch);

			// append fetched variants to current variant data
			const mergedVariants = [
				...variantsQuantity.value,
				...quantity,
			];

			setVariantsQuantity(mergedVariants);
		} catch (error) {
			console.error(error);
		}

		const updatedCartItems = await refreshCartItems({
			cartItems: getCartData(),
		});

		setShoppingCartItems(updatedCartItems);
	};

	const updateShoppingCartItems = (items: any) => {
		const storageValue = {
			payload: items,
			expiry: Date.now() + SHOPPING_CART_TTL,
		};

		window.localStorage.setItem(SHOPPING_CART_STORAGE_KEY, JSON.stringify(storageValue));

		setShoppingCartItems(items);
	};

	const fetchProducts = async (productIds: string[]) => {
		const storeId = getStoreId(getters.meta);

		if (!storeId) {
			return;
		}

		const cartItems = getCartData();
		const cartProductIds = cartItems.map((product: any) => product.id);
		const idsToFetch = [
			...cartProductIds,
			...productIds,
		].reduce((accumulator, id) => {
			if (products.value.some((product) => product.id === id)) {
				return accumulator;
			}

			return [
				...accumulator,
				id,
			];
		}, []);

		if (!idsToFetch.length) {
			return;
		}

		// !IMPORTANT to reset isLoaded for animations
		// when not all page products are loaded, only the ones in cart, when this isLoaded=true,
		// animations will not be observed on mounted in BlockUser.vue because products load later and need to be watched into
		setIsLoaded(false);
		setIsLoading(true);

		try {
			const storeProducts = await getStoreProducts(storeId, idsToFetch);
			const mergedProducts = storeProducts.reduce((accumulator: any, product: any) => {
				const exists = accumulator.some((item: any) => item.id === product.id);

				if (exists) {
					return accumulator;
				}

				return [
					...accumulator,
					product,
				];
			}, [...products.value]);

			setStoreProducts(mergedProducts);

			await updateVariantsQuantity(idsToFetch);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			setIsLoaded(true);
		}
	};

	return {
		products,
		shoppingCartItems,
		selectedBookingProductId,
		variantsQuantity,
		isShoppingCartOpen,
		isCheckoutLoading,
		isLoading,
		isLoaded,
		isProductPageLoaded,
		isStoreTypeZyro,
		isEcommerceStoreCreated,
		quantifiedCartItemsList,
		canAddToCart,
		productPages,
		setIsLoading,
		setIsLoaded,
		setIsCheckoutLoading,
		setStoreProducts,
		setShoppingCartOpen,
		setShoppingCartItems,
		setSelectedBookingId,
		setVariantsQuantity,
		fetchProducts,
		updateVariantsQuantity,
		refreshCartItems,
		updateShoppingCartItems,
	};
};
